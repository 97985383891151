import React from "react";
import Header from "src/components/Header";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <Header></Header>
        <div className="main row">
          <div className="sidebar three columns">
            <ul className="side-nav">
              <li className="first overview">
                <a href="/imodium-behandelen-diarree/">
                  {" "}
                  IMODIUM® Geneesmiddelen{" "}
                </a>
              </li>
              <li className="active">
                <a href="/imodium-behandelen-diarree/imodium-instant/">
                  IMODIUM® Instant Smelttablet
                </a>
              </li>
              <li className="last">
                <a href="/imodium-behandelen-diarree/imodiumreg-capsules/">
                  IMODIUM® Capsules
                </a>
              </li>
            </ul>
            <div className="widget">
              <div className="inner">
                <h4>Tips bij diarree</h4>
                <div className="img">
                  <img
                    src="/assets/files/widgets/images/teaser_durchfallmythen.jpeg"
                    width="170"
                    height="102"
                    alt="Tips bij diarree"
                  />
                </div>
                <p>
                  Rond het behandelen van de symptomen van diarree bestaan er
                  nog vele misverstanden en&nbsp;mythes. Hier vind je enkele
                  tips over het behandelen van de symptomen van diarree.
                </p>
                <a href="/feiten-over-diarree/tips-voor-de-behandeling-van-diarree/">
                  Tips bij diarree
                </a>
              </div>
            </div>
          </div>
          <div className="page nine columns page-42">
            <h1>IMODIUM® Instant Smelttablet</h1>
            <div className="row">
              <div className="six columns">
                <img
                  alt
                  src="/assets/files/products/images/Imodium-Smelttablet-2mg-3D-Frontal-NL.png"
                  style={{
                    width: "322px",
                    height: "187px"
                  }}
                />
              </div>
              <div className="six columns">
                <h3>IMODIUM® Instant smelttablet: handige hulp bij diarree</h3>
                <ul>
                  <li>Makkelijk zonder water en zonder slikken in te nemen</li>
                  <li>Smelt in enkele seconden op je tong.</li>
                  <li>Werkt snel en doeltreffend.</li>
                </ul>
              </div>
            </div>
            <div className="row">
              <p>
                <a href="http://bit.ly/2j7gXeh" target="_blank">
                  <img
                    alt="Bijsluiter IMODIUM® Instant downloaden"
                    src="/assets/files/pages/bijlsuiter.jpeg"
                    style={{
                      width: "48px",
                      height: "47px"
                    }}
                  />{" "}
                  Bijsluiter IMODIUM® Instant smelttablet downloaden
                </a>
              </p>
              <h3>Gebruik van IMODIUM®</h3>
              <p>
                IMODIUM® Instant smelttablet bevat 2 mg loperamide. Het wordt
                gebruikt bij de symptomatische behandeling van acute diarree
                (plotselinge, kortdurende) en chronische diarree van diverse
                oorsprong.
              </p>
              <h4>Instant bij diarree</h4>
              <p>
                IMODIUM<sup>®</sup> Instant&nbsp;smelttablet op basis van
                loperamide normaliseert de overmatige darmactiviteit, zodat de
                stoelgang weer vaster wordt en je diarree verdwijnt.
              </p>
              <p>
                Zo wordt uitdroging door verlies van vocht en mineralen
                beperkt.&nbsp;IMODIUM® Instant smelttablet kan ook aanbevolen
                worden bij reizigersdiarree. Hierbij moet een behandeling van 2
                dagen volstaan.&nbsp;
              </p>
              <p>
                IMODIUM® Instant smelttablet&nbsp;is bijzonder handig bij
                behandeling van de symptomen van diarree als je moeite hebt met
                het slikken van tabletten of als je onderweg bent
                (reizigersdiarree). De IMODIUM® Instant smelttabletten hebben
                een aangename muntsmaak.
              </p>
              <p>
                IMODIUM® instant smelttablet&nbsp; voor de behandeling van de
                symptomen van diarree bestaat als verpakking met 10
                smelttabletten. Het middel is in de apotheek, drogisterij en
                supermarkt zonder voorschrift te verkrijgen.
              </p>
              <h4>IMODIUM® Instant smelttablet, 2 mg</h4>
              <p>
                IMODIUM® instant smelttablet is geschikt voor volwassenen en
                kinderen vanaf 8 jaar. Bevat loperamide. Lees altijd de
                bijsluiter.
              </p>
              <p>
                De IMODIUM® Instant tabletten smelten zonder extra water op je
                tong.
              </p>
              <h3>
                <img
                  alt="Werkzame stof van IMODIUM® Instant:"
                  src="/assets/files/pages/werkzame-stof.jpeg"
                  style={{
                    width: "26px",
                    height: "26px"
                  }}
                />
                &nbsp;Werkzame stof van IMODIUM® Instant smelttablet:
              </h3>
              <p>
                De werkzame stof van IMODIUM® Instant voor symtomatische
                behandeling van diarree en de symptomatische&nbsp;
                <strong>behandeling van reizigersdiarree</strong> (diarree op
                reis) is{" "}
                <a href="/hoe-werkt-imodium-instant/werkzame-stof-loperamide/">
                  loperamide
                </a>{" "}
                (Loperamidehydrochloride).
              </p>
              <p>&nbsp;</p>
              <h3>
                <img
                  alt="Toepassingsgebied IMODIUM® Instant:"
                  src="/assets/files/pages/toepassingsgebied.jpeg"
                  style={{
                    width: "26px",
                    height: "26px"
                  }}
                />
                &nbsp;Toepassingsgebied IMODIUM® Instant smelttablet:
              </h3>
              <p>
                Symptomatische behandeling van acute diarree en chronische
                diarree van diverse oorsprong. Kan aanbevolen worden bij
                reizigersdiarree. Bij reizigersdiarree moet een behandeling van
                2 dagen volstaan.
              </p>
              <p>&nbsp;</p>
              <h4>Dosering van IMODIUM® Instant smelttablet:</h4>
              <ul>
                <li>
                  <b>Volwassenen:</b>
                  <ul>
                    <li>
                      Bij acute (plotselinge) diarree: Begin met 2 tabletten (4
                      mg). Neem daarna na elke daaropvolgende losse ontlasting 1
                      tablet (2 mg), zolang de diarree duurt. Neem nooit meer
                      dan 8 tabletten (16 mg) op één dag.
                    </li>
                    <li>
                      Bij chronische (langdurige) diarree: Begin met 2 tabletten
                      (4 mg) per dag. Vervolgens deze dosis bijstellen, totdat u
                      1 tot 2 maal per dag vaste ontlasting heeft. Meestal wordt
                      dit bereikt met 1 tot 6 tabletten (2 tot 12 mg) per dag. U
                      mag nooit meer dan 8 tabletten (16 mg) op één dag innemen.
                    </li>
                  </ul>
                </li>
                <li>
                  <b>Kinderen vanaf 8 jaar:</b>
                  <ul>
                    <li>
                      Bij acute (plotselinge) diarree: Begin met 1 tablet (2
                      mg). Daarna na elke daaropvolgende losse ontlasting 1
                      tablet (2 mg), zolang de diarree duurt. Het aantal
                      tabletten dat een kind op één dag in mag nemen, is
                      afhankelijk van het lichaamsgewicht van het kind. Zie{" "}
                      <a href="https://www.geneesmiddeleninformatiebank.nl/Bijsluiters/h33724.pdf" target="_blank">
                        bijsluiter
                      </a>{" "}
                      voor meer informatie. Bij kinderen dient men er tevens op
                      te letten dat men nooit meer dan 8 tabletten per dag
                      toedient.
                    </li>
                    <li>
                      Bij chronische (langdurige) diarree: Begin met 1 tablet (2
                      mg) per dag. Vervolgens deze dosis bijstellen, totdat het
                      kind 1 tot 2 maal per dag vaste ontlasting heeft. Meestal
                      wordt dit bereikt met 1 tot 6 tabletten (2 tot 12 mg) per
                      dag. Het aantal tabletten dat een kind op één dag in mag
                      nemen, is afhankelijk van het lichaamsgewicht van het
                      kind. Zie{" "}
                      <a href="https://www.geneesmiddeleninformatiebank.nl/Bijsluiters/h33724.pdf" target="_blank">
                        bijsluiter
                      </a>{" "}
                      voor meer informatie. Bij kinderen dient men er tevens op
                      te letten dat men nooit meer dan 8 tabletten per dag
                      toedient.
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <Footer></Footer>
        <div id="myModal" className="reveal-modal large">
          <div
            className="flash-wrapper"
            style={{
              "max-width": "100%",
              height: "236px",
              width: "428px",
              margin: "0 auto"
            }}
          >
            <div id="flash">
              <p>
                Your browser must have Adobe Flash installed to see this
                animation.
              </p>
            </div>
          </div>
          <a className="close-reveal-modal">×</a>
        </div>
        <input type="hidden" name="language" defaultValue="nl-BE" id="lg" />
        <script src="//ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
         <script src="https://code.jquery.com/jquery-migrate-3.2.0.js"></script>
        <script src="/assets/js/custom.js" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "window.jQuery || document.write('<script src=\"/assets/js/vendor/jquery-3.4.1.min.js\"><\\/script>')"
          }}
        />
        <script src="/assets/js/vendor/jquery.flexslider-min.js" />
        <script src="/assets/js/vendor/hash.js" />
        <script src="/assets/js/foundation/jquery.foundation.navigation.js" />
        <script src="/assets/js/foundation/jquery.foundation.topbar.js" />
        <script src="/assets/js/foundation/jquery.foundation.forms.js" />
        <script src="/assets/js/foundation/jquery.foundation.reveal.js" />
        <script src="/assets/js/vendor/swfobject.js" />
        <script src="/assets/js/vendor/yepnope.1.5.4-min.js" />
        <script src="/assets/js/app.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nfunction cookiesDirectiveScriptWrapper(){\n// declare teh used cookie-3rd-parties\n}\n"
          }}
        />
         
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// The position of the disclosure ('top' or 'bottom')\n// Number of times to display disclosure. Enter 0 to show it forever!!!!\n// The URI of your privacy policy\ncookiesDirective('bottom',5,'/cookiebeleid');\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
